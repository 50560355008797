<template>
  <div class="todo-wrapper">
    <h4 class="title gr-medium">To-do</h4>
    <Spinner v-if="isLoading" class="flex" />
    <div v-else>
      <div v-if="pendingList.length === 0" class="todo-completed p-d-flex">
        <div class="text-color-primary">
          <SvgIcon name="todo_completed" size="7rem" />
        </div>
        <p class="lt-txt-color font-size-1">Your application is currently under review</p>
      </div>
      <div v-else class="todo-list">
        <div v-for="(task, index) of pendingList" :key="index || task.id" class="list p-d-flex p-ai-center">
          <div
            :style="
              width < 545 && {
                border: `1.5px solid ${
                  task.status === 'open'
                    ? '#ffbcbd'
                    : task.status == 'review' || task.status == 'in_progress'
                    ? '#a4bfe0'
                    : 'var(--primary-color)'
                } !important`,
                color: `${
                  task.status === 'open'
                    ? '#ffbcbd'
                    : task.status == 'review' || task.status == 'in_progress'
                    ? '#a4bfe0'
                    : 'var(--primary-color)'
                }`,
                background: '#ffffff',
              }
            "
            class="icon-content-wrapper col-7"
            @click="mobileCta(task)"
          >
            <i class="icon mb-none pi pi-exclamation-circle"></i>
            <div class="content">
              <p class="heading">
                {{ fromSnakeCase(task.taskTitle) }}
              </p>
              <p v-if="task.externalDescription" class="text">
                {{ task.externalDescription }}
              </p>
            </div>
          </div>
          <div
            v-if="
              ((isIncomeAndExpenseRequestTask(task) &&
                task.assignedToApplicantId === profileDetails.id ||
                isSingleDeclarationTask(task) &&
                task.assignedToApplicantId === profileDetails.id) && 
                shouldShowTaskAction(task)) ||
              (!isIncomeAndExpenseRequestTask(task) && !isSingleDeclarationTask(task) && shouldShowTaskAction(task))
            "
            @click="clickTaskAction(task)"
            class="pr-4 mb-none"
          >
            <Button
              :data-test-id="getDataTestId(task, 'action-button')"
              @click="changeDialog(task)"
              class="mt-3 ml-2 mb-none"
              :label="getButtonText(task)"
              type="primary"
              width="120px"
              v-if="task.crmStatus == 'document_issued' || task.crmTask.modalField != 'no_action'"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TOKEN } from '@/constants';
import { fromSnakeCase } from '@/services/format';
import { mapActions, mapGetters } from 'vuex';
import { useCookies } from 'vue3-cookies';
import SvgIcon from '../../../../../components/common/SvgIcon.vue';
const { cookies } = useCookies();

export default {
  components: {
    SvgIcon,
  },
  props: [
    'application',
    'openDialog',
    'completed',
    'progressStatus',
    'openUnconditional',
    'openCondtional',
    'openBasic',
    'loanAmountSettled',
    'getProgress',
    'openDischargeRequest',
  ],
  data() {
    return {
      pendingList: [],
      isLoading: true,
      statusTask: '',
      width: '',
    };
  },
  computed: {
    ...mapGetters({
      selectedLoanProgress: 'loans/selectedLoanProgress',
      tasksList: 'loans/tasksList',
      profileDetails: 'settings/profileDetails',
      selectedLoanDetails: 'loans/selectedLoanDetails',
    }),
  },
  methods: {
    fromSnakeCase,
    ...mapActions({
      fetchLoanProgress: 'loans/fetchLoanProgress',
      setSelectedTaskId: 'loans/setSelectedTaskId',
      setCrmTaskId: 'loans/setCrmTaskId',
      setIsSupporting: 'loans/setIsSupporting',
      setLoanStatus: 'loans/setLoanStatus',
      getTasksByApplicationId: 'loans/getTasksByApplicationId',
    }),
    clickTaskAction(task) {
      if (task.crmTask.modalField == 'address') {
        this.setCrmTaskId(task.crmTaskId);
      }
      this.$emit('setSelectedTask', task);
      this.setSelectedTaskId(task.id);
    },
    mobileCta(task) {
      if (
        this.width < 545 &&
        task.crmTask.modalField != 'declaration_1' &&
        task.crmTask.modalField != 'declaration_2'
      ) {
        this.changeDialog(task);
        this.clickTaskAction(task);
      }
    },

    /**
     * Generates a unique id for the element for Playwright
     */
    getDataTestId(task, key) {
      let isApplicant, applicantIndex;
      for (const applicant of this.selectedLoanDetails.applicants) {
        isApplicant = task.taskTitle.endsWith(`${applicant.firstName} ${applicant.lastName}`);
        if (isApplicant) {
          applicantIndex = this.selectedLoanDetails.applicants.indexOf(applicant);
          break;
        }
      }
      if (isApplicant) {
        return 'task-' + task.crmTask.title + '-applicant-' + applicantIndex + '-' + key;
      }
      let isIncomingProperty, incomingPropertyIndex;
      for (const property of this.selectedLoanDetails.incomingProperties) {
        isIncomingProperty = task.taskTitle.endsWith(property.line1);
        if (isIncomingProperty) {
          incomingPropertyIndex = this.selectedLoanDetails.incomingProperties.indexOf(property);
          break;
        }
      }
      if (isIncomingProperty) {
        return 'task-' + task.crmTask.title + '-incoming-property-' + incomingPropertyIndex + '-' + key;
      }
      let isOutgoingProperty, outgoingPropertyIndex;
      for (const property of this.selectedLoanDetails.outgoingProperties) {
        isOutgoingProperty = task.taskTitle.endsWith(property.line1);
        if (isOutgoingProperty) {
          outgoingPropertyIndex = this.selectedLoanDetails.outgoingProperties.indexOf(property);
          break;
        }
      }
      if (isOutgoingProperty) {
        return 'task-' + task.crmTask.title + '-outgoing-property-' + outgoingPropertyIndex + '-' + key;
      }
      return 'task-' + task.crmTask.title + '-' + key;
    },

    /**
     * Opens the appropriate modal dialog based on the CRM task
     */
    async changeDialog(task) {
      if (task.crmTask.modalField) {
        if (task.crmTask.modalField == 'address') {
          this.setCrmTaskId(task.crmTaskId);
          this.$emit('openBasic');
        } else if (task.crmTask.modalField == 'conditional') {
          this.setSelectedTaskId(task.id);
          this.$emit('openCondtional');
        } else if (task.crmTask.modalField == 'unconditional') {
          this.$emit('openUnconditional');
        } else if (task.crmTask.modalField == 'supporting' || task.crmTask.modalField == 'upload') {
          this.$emit('setSelectedTask', task);
          this.setIsSupporting(true);
          this.$emit('openDialog', task);
        } else if (task.crmTask.modalField == 'discharge') {
          this.setSelectedTaskId(task.id);
          this.$emit('supportingDocumentId', task?.supportingDocument?.id);
          this.$emit('taskId', task?.id);
          this.$emit('openDischargeRequest');
        } else if (task.crmStatus == 'document_issued' || task.crmTask.modalField == 'document_issued') {
          this.updateTask(task.id);
          return;
        }
        // } else {
        //   await _put(TECHLEND_BE_URL + 'update_application_task_status?id=' + task.Id, {
        //     application_id: this.selectedLoanId,
        //     status: 'in_progress' || 'review',
        //   });
        //   this.$emit('refresh');
        // }
      } else {
        if (task.crmTask.isAttachmentRequired) {
          this.setIsSupporting(false);
        }
        // } else {
        //   await _put(TECHLEND_BE_URL + 'update_application_task_status?id=' + task.Id, {
        //     application_id: this.selectedLoanId,
        //     status: 'in_progress' || 'review',
        //   });
        //   this.$emit('refresh');
        // }
        else if (this.isIncomeAndExpenseRequestTask(task)) {
          const token = cookies.get(TOKEN);
          const income_and_expenses_url = `${process.env.VUE_APP_LOAN_APPLICATION_URL}?from=crm&appId=${this.selectedLoanDetails.id}&appType=${task.applicantType}&t=${token}`;
          window.open(income_and_expenses_url, '_blank');
        } else if (this.isSingleDeclarationTask(task)) {
          const token = cookies.get(TOKEN);
          const income_and_expenses_url = `${process.env.VUE_APP_LOAN_APPLICATION_URL}?appId=${this.selectedLoanDetails.id}&appType=${task.applicantType}&t=${token}`;
          window.open(income_and_expenses_url, '_blank');
        }
      }
    },

    /**
     * Determines the text to use for the button based on the task's CRM Task
     */
    getButtonText(task) {
      if (this.isIncomeAndExpenseRequestTask(task)) {
        return 'Submit';
      }
      if (task.crmTask.modalField) {
        if (task.crmTask.modalField == 'address') {
          return 'Update';
        } else if (task.crmTask.modalField == 'conditional' || task.crmTask.modalField == 'unconditional') {
          return 'Accept';
        } else if (task.crmTask.modalField == 'supporting_document') {
          return 'Upload file';
        } else if (task.crmTask.modalField == 'discharge_request') {
          return 'Submit';
        } else if (task.crmTask.modalField == 'bank') {
          return 'Connect';
        } else if (task.crmTask.modalField == 'upload') {
          return 'Upload';
        } else {
          return 'Complete';
        }
      } else {
        if (task.crmTask.isAttachmentRequired) {
          return 'Upload file';
        } else {
          return 'Complete';
        }
      }
    },
    async setData() {
      const selectedApplicationId = this.selectedLoanDetails.id;
      await this.getTasksByApplicationId(selectedApplicationId);
    },

    /**
     * Filters the task list to only include the "todo" items with a status of not_yet_Started
     */
    setTasksList() {
      this.pendingList = this.tasksList
        .filter((task) => task.status === 'not_yet_started')
        .map((task) => {
          const applicant = this.selectedLoanDetails.applicants?.find((app) =>
            task.taskTitle.endsWith(`${app.firstName} ${app.lastName}`),
          );

          const applicantType = applicant?.applicantType || (task.assignedTo === 'broker' ? 'broker' : 'primary');

          return {
            ...task,
            applicantType,
          };
        });
    },
    isIncomeAndExpenseRequestTask(task) {
      return task.crmTask.title === 'income_and_expense_request';
    },
    isSingleDeclarationTask(task) {
      return task.crmTask.title === 'sign_declaration';
    },
    isExcludedModalField(task) {
      const excludedFields = ['declaration_1', 'declaration_2'];
      return excludedFields.includes(task.crmTask.modalField);
    },
    shouldShowTaskAction(task) {
      return !this.isExcludedModalField(task);
    },

    async updateTask(/* taskId */) {
      // TODO: document_issued is not implemented
      // try {
      //   await _patch(TECHLEND_BE_URL + API.TASKS.UPDATE_TASK + `?id=${taskId}`, {
      //     status: 'in_progress' || 'review',
      //   });
      //   this.$emit('refresh');
      // } catch (err) {
      //   if (err.response && err.response.data && err.response.data.message) {
      //     await this.$toast.add({
      //       severity: 'error',
      //       summary: 'Error',
      //       detail: err.response.data.message,
      //       life: 3000,
      //     });
      //   } else {
      //     await this.$toast.add({
      //       severity: 'error',
      //       summary: 'Error',
      //       detail: 'Something went wrong',
      //       life: 3000,
      //     });
      //   }
      // }
    },
  },

  async created() {
    this.isLoading = true;
    this.setData();
    this.statusTask = this.selectedLoanDetails.applicationStatus;
    this.setLoanStatus(this.statusTask);
    this.setTasksList();
    this.isLoading = false;
    this.width = window.innerWidth;
    window.addEventListener('resize', this.onResize);
    this.getYear = new Date().getFullYear();
  },

  onResize() {
    this.width = window.innerWidth;
  },

  destroyed() {
    window.removeEventListener('resize', this.onResize);
  },
  watch: {
    tasksList() {
      this.setTasksList();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';

.todo-wrapper {
  height: 400px;
  overflow: auto;
  width: 50%;
  margin-top: 2rem;
  position: relative;
  @media (max-width: 992px) {
    width: 100%;
  }

  .todo-completed {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    justify-content: center;

    p {
      margin: 0;
      text-align: center;
    }
  }

  .title {
    color: $primary-text-color;
  }

  .todo-list {
    margin-top: 1rem;
  }

  .list {
    border-bottom: 1px solid #bbbbbb;
    gap: 1.5rem;
    padding-bottom: 1rem;
    justify-content: space-between;

    @media (max-width: 992px) {
      gap: 0.5rem;
    }

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    .icon-content-wrapper {
      display: flex;
      align-items: center;
      gap: 1rem;

      .icon {
        font-size: 1.7rem;
        color: $primary-color;
      }
    }

    .content {
      .heading {
        margin: 0;
        color: $primary-text-color;
        font-weight: bold;
        font-size: 1rem;
      }

      .text {
        // margin: 0;
        // color: #808080;
        font-size: 0.9rem;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: rgba(2, 0, 56, 0.3);
      }
    }
  }

  @media (max-width: 545px) {
    .mb-none {
      display: none;
    }

    .icon-content-wrapper {
      width: 100% !important;
      display: block !important;
      text-align: center;
      padding: 0;
      flex: 100%;
      border: 1px solid black;
      border-radius: 4px;
      padding: 1rem;
    }

    .text {
      margin-bottom: 0;
    }

    .list {
      justify-content: center !important;
    }
  }
}
</style>
